import { useRouter } from 'next/router'

// pathname =>  from route path const file
const useRouteMatch = (pathname: string) => {
  const router = useRouter()

  const currPathname = router.pathname.substr(-1) !== '/' ? `${router.pathname}/` : router.pathname
  const routePathname = pathname.substr(-1) !== '/' ? `${pathname}/` : pathname

  return currPathname === routePathname
}

export default useRouteMatch
