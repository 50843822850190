import { useRouter } from 'next/router'
import { useEffect } from 'react'

import { getBrowserCookie, getUtmParams, setBrowserCookie } from 'lib/utils'

import { COOKIES_SHOPBACK_TRANSACTION_ID, COOKIES_SHOPBACK_TRANSACTION_ID_EXPIRYATION } from 'lib/constants'
import { PRODUCT_ROUTE } from 'lib/constants/routes'

import useRouteMatch from './useRouteMatch'

export const SHOPBACK = 'shopback'

const useShopback = () => {
  const { query } = useRouter()
  const isProductDetailPage = useRouteMatch(PRODUCT_ROUTE)

  useEffect(() => {
    let { utm_source, transaction_id } = query

    if (isProductDetailPage && !utm_source && !transaction_id) {
      // useRouter hook not working properly for cached page to get query params hence native javascript way is used
      // assumption is cached page is render immediately while nextjs resources are yet to load or not ready
      const urlSearchParams = new URLSearchParams(window.location.search)
      const queryParams = Object.fromEntries(urlSearchParams.entries())
      utm_source = queryParams.utm_source
      transaction_id = queryParams.transaction_id
    }

    if (utm_source === SHOPBACK && transaction_id) {
      setBrowserCookie(
        COOKIES_SHOPBACK_TRANSACTION_ID,
        transaction_id as string,
        COOKIES_SHOPBACK_TRANSACTION_ID_EXPIRYATION
      )
    }
  }, [query, isProductDetailPage])
}

const getShopbackTransactionId = () => {
  const lastClickAttribution = getUtmParams('lastClick') as { source?: string }

  if (lastClickAttribution && lastClickAttribution?.source === SHOPBACK) {
    return getBrowserCookie(COOKIES_SHOPBACK_TRANSACTION_ID)
  }

  return null
}

const clearShopbackTransactionId = () => {
  setBrowserCookie(COOKIES_SHOPBACK_TRANSACTION_ID, '', -99999999)
}

export { useShopback, getShopbackTransactionId, clearShopbackTransactionId }
