import { useEffect, useMemo, useRef } from 'react'
import { useRouter } from 'next/router'
import uuid from 'uuid-random'

import useLayoutEffect from 'lib/hooks/useLayoutEffect'

import { pageViewed, track } from 'lib/utils/track'
import { getBrowserCookie, isServer } from 'lib/utils'
import { useAppData } from 'lib/context/app-data-context'
import { useGlobalContext } from 'lib/context/global-context'
import { getDsUserId, getUserStorageData } from 'lib/utils/auth'

import { COOKIES_DS_REF, REFERER_STORAGE_KEY } from 'lib/constants'
import { EVENTS } from 'lib/constants/events'

interface TrackContextProps {
  pageName: string
  pageMainAttributeType?: string
  pageId?: string
  additionalParams?: any
  pageViewedDeps?: any[]
  pageViewAdditionalParams?: Record<string, string | object>
  trackElapsedTime?: boolean
  pageViewedRequirement?: () => boolean
}

const useTracking = ({
  pageName,
  pageMainAttributeType,
  pageId,
  additionalParams,
  pageViewedDeps = [],
  // only used for page level track events
  pageViewAdditionalParams = {},
  trackElapsedTime,
  pageViewedRequirement,
}: TrackContextProps) => {
  const { geoLocation } = useGlobalContext()
  const { getTrackContextData } = useAppData()

  const firstEventTimestampRef = useRef<Date>(new Date())

  const { query } = useRouter()

  const otherTrackParams = useMemo(() => {
    const { customerId } = getUserStorageData()?.user || {}
    const pageViewId = uuid()
    const dsUserId = getDsUserId()
    const referer = getBrowserCookie(REFERER_STORAGE_KEY)
    return { pageViewId, dsUserId, customerId, referer }
  }, [pageId]) // eslint-disable-line react-hooks/exhaustive-deps

  const otherTrackParamsWithRef = useMemo(() => {
    const refId = query.ref || getBrowserCookie(COOKIES_DS_REF)
    return { ...otherTrackParams, ref: refId }
  }, [otherTrackParams, query.ref])

  useLayoutEffect(() => {
    if (pageViewedRequirement && !pageViewedRequirement()) return

    const _pageViewed = async () => {
      const trackParams = await getTrackContextData()
      const timestamp = new Date()

      pageViewed({
        pageName,
        attributeId: pageName,
        ...(pageMainAttributeType && { pageMainAttributeType }),
        ...trackParams,
        ...otherTrackParamsWithRef,
        ...additionalParams,
        ...pageViewAdditionalParams,
        // once city header is removed from ngnix, we can remove all the references to trackParams?.geoCity
        geoCity: trackParams?.geoCity || geoLocation?.cityName,
      })

      firstEventTimestampRef.current = timestamp
      // push to GTM -> to -> customer.io
      window?.dataLayer?.push?.({
        countryId: trackParams?.geoCountryCode,
        cityId: trackParams?.geoCity || geoLocation?.cityName,
      })
    }

    _pageViewed()
  }, pageViewedDeps) // eslint-disable-line react-hooks/exhaustive-deps

  const trackEvent = async (params: any, options?: any) => {
    if (isServer) return

    let timestampParams = {}
    if (trackElapsedTime) {
      const timestamp = new Date()
      // elapsed time in seconds
      const elapsedTime = (timestamp.getTime() - firstEventTimestampRef.current.getTime()) / 1000

      timestampParams = {
        elapsedTime,
      }
    }

    const trackParams = await getTrackContextData()
    track({
      pageName,
      ...(pageMainAttributeType && { pageMainAttributeType }),
      ...trackParams,
      ...otherTrackParamsWithRef,
      ...additionalParams,
      ...params,
      ...(options?.pageNameAsAttributeId && { attributeId: pageName }),
      geoCity: trackParams?.geoCity || geoLocation?.cityName,
      ...timestampParams,
    })
  }

  const formatEventAttribute = (args: string[]) => {
    return args.reduce((prevId, currId) => `${prevId}_${currId}`)
  }

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'hidden') {
        trackEvent({
          attributeId: pageName,
          attributeType: EVENTS.ATTRIBUTES_TYPE.PAGE,
          eventType: EVENTS.EXIT,
        })
      } else {
        trackEvent({
          attributeId: pageName,
          attributeType: EVENTS.ATTRIBUTES_TYPE.PAGE,
          eventType: EVENTS.ENTER,
        })
      }
    }

    if (trackElapsedTime) {
      document.addEventListener('visibilitychange', handleVisibilityChange)
    }

    return () => {
      if (trackElapsedTime) {
        trackEvent({
          attributeId: pageName,
          attributeType: EVENTS.ATTRIBUTES_TYPE.PAGE,
          eventType: EVENTS.EXIT,
        })
        document.removeEventListener('visibilitychange', handleVisibilityChange)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    trackEvent,
    formatEventAttribute,
    pageViewId: additionalParams?.pageViewId || otherTrackParamsWithRef.pageViewId,
  }
}

export default useTracking
