import React, { forwardRef, useMemo } from 'react'
import cn from 'classnames'

import { Icon } from 'ui/icon'
import { Image } from 'ui/image'

import { getProductPlaceholderImages } from 'page-modules/product/utils'

import { useGlobalContext } from 'lib/context/global-context'

import s from './styles.module.scss'

interface MediaData {
  url?: string
  caption?: string
  sizes?: Record<'large' | 'medium' | 'small', string>
}
interface ProductCardProps {
  className?: string
  productName: string
  destinationId: string
  countryId: string
  destinationName?: string
  countryName?: string
  thumbnail: MediaData
  onClick: () => void
}

const ProductCard = forwardRef<HTMLDivElement, ProductCardProps>(
  ({ className, productName, destinationName, countryName, thumbnail, onClick }, ref) => {
    const { isMobileView } = useGlobalContext()

    const imageSrc = useMemo(() => {
      if (thumbnail?.sizes) {
        return thumbnail
      } else {
        return { ...thumbnail, ...getProductPlaceholderImages() }
      }
    }, [thumbnail])

    return (
      <div ref={ref} className={cn(s.card, className)} onClick={onClick} tabIndex={-1}>
        <div>
          <Image
            src={imageSrc}
            alt={productName}
            size="xsmall"
            width={isMobileView ? 64 : 56}
            height={isMobileView ? 64 : 56}
            className={s.product}
          />
        </div>

        <div className={s.body}>
          <div className={s.content}>
            <h4 className={s.title}>{productName}</h4>
            {destinationName && (
              <div className={s.description}>
                <Icon name="map-pin" size="mini" />
                <span className={s.location}>{`${destinationName}${
                  countryName ? `, ${countryName}` : ''
                }`}</span>
              </div>
            )}
          </div>
          <Icon name="arrow-right" size="medium" className={s.hoverIcon} />
        </div>
      </div>
    )
  }
)

ProductCard.displayName = 'ProductCard'

export { ProductCard }
