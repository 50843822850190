import { SearchTab } from 'lib/@Types'

import { PAGE_SIZE } from 'lib/constants'
import { EVENTS } from 'lib/constants/events'

interface ExposureParams {
  destinations: any
  products: any
  query?: string
  isCompact?: boolean
  recommendType?: 'recent' | 'trending'
  trackEvent?: TrackEventType
  queries?: never
}

export const searchExposureOnEmptyResults = ({
  query,
  isCompact = false,
  trackEvent,
  products,
  destinations,
}: ExposureParams) => {
  if (destinations?.length || products?.length) return

  trackEvent?.({
    isCompact,
    eventType: EVENTS.TYPE.EXPOSURE,
    attributeId: EVENTS.AUTO_SUGGEST_SEARCH_INPUT,
    attributeType: EVENTS.ATTRIBUTES_TYPE.RESULT,
    attributeValue: {
      query,
      results: [],
    },
  })
}

// product tab means display only products in search result
export const isProductTabActive = (tab: SearchTab) => {
  return tab === 'product'
}

// destination tab means display only destinations in search result
export const isDestinationTabActive = (tab: SearchTab) => {
  return tab === 'destination'
}

// destination filter means any destination filter applied or not
export const isDestinationFilterActive = (destinationId: string) => {
  return !!destinationId
}

export const wantProductsInResult = (tab: SearchTab) => {
  return !isDestinationTabActive(tab)
}
export const wantDestinationsInResult = (tab: SearchTab, destinationId: string) => {
  return !(isProductTabActive(tab) || isDestinationFilterActive(destinationId))
}

export const getAutoSuggestFilter = ({
  destinationId,
  countryId,
}: {
  destinationId?: string | undefined
  countryId?: string | undefined
}) => {
  if (destinationId) {
    const pageSize = PAGE_SIZE.AUTO_SUGGEST_PRODUCT + PAGE_SIZE.AUTO_SUGGEST_DESTINATION

    return {
      autoSuggestSearchInput: {
        destinationIds: [destinationId],
        pageSize,
      },
      withDestination: false,
    }
  }

  if (countryId) {
    const pageSize = PAGE_SIZE.AUTO_SUGGEST_COUNTRY + PAGE_SIZE.AUTO_SUGGEST_PRODUCT

    return {
      autoSuggestSearchInput: {
        countryIds: [countryId],
        pageSize,
      },
      withDestination: false,
    }
  }
  return {
    autoSuggestSearchInput: {
      pageSize: PAGE_SIZE.AUTO_SUGGEST_PRODUCT,
    },
    withDestination: true,
    destinationPageSize: PAGE_SIZE.AUTO_SUGGEST_DESTINATION,
  }
}

export const getSearchResultFilter = ({
  destinationId,
  countryId,
  page,
  tab,
  params,
}: {
  destinationId: string
  countryId: string
  page: number
  tab: SearchTab
  params: any
}) => {
  return {
    destination: destinationId,
    country: countryId,
    withDestination: wantDestinationsInResult(tab, destinationId),
    withProduct: wantProductsInResult(tab),
    page: page || 1,
    destinationPageSize: PAGE_SIZE.SEARCH_RESULT_DESTINATION,
    ...(params || {}),
  }
}
