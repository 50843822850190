import React, { forwardRef } from 'react'
import cn from 'classnames'

import { Icon } from 'ui/icon'

import s from './styles.module.scss'

interface DestinationCardProps {
  className?: string
  name: string
  countryName: string
  onClick: () => void
}

const DestinationCard = forwardRef<HTMLDivElement, DestinationCardProps>(
  ({ className, name, countryName, onClick }, ref) => (
    <div ref={ref} className={cn(s.card, className)} onClick={onClick} tabIndex={-1}>
      <div className={s.destination}>
        <Icon name="map-pin" size="medium" />
      </div>
      <div className={s.body}>
        <div className={s.content}>
          <h4 className={s.title}>
            {name}, {countryName}
          </h4>
        </div>
        <Icon name="arrow-right" size="medium" className={s.hoverIcon} />
      </div>
    </div>
  )
)

DestinationCard.displayName = 'DestinationCard'

export { DestinationCard }
