import { ParsedUrlQuery } from 'querystring'

import { useEffect } from 'react'

import {
  getBrowserCookie,
  generatePartnerSession,
  generateUserCurrency,
  setBrowserCookie,
  generateDsUserId,
  generateUtms,
  isBrowser,
  serialiseCookie,
} from 'lib/utils'

import { PRODUCT_ROUTE } from 'lib/constants/routes'
import {
  COOKIES_CURRENCY,
  COOKIES_DS_USER_ID,
  COOKIES_FIRST_CLICK,
  COOKIES_FIRST_CLICK_EXPIRATION,
  COOKIES_LAST_CLICK,
  COOKIES_LAST_CLICK_EXPIRATION,
  COOKIES_PARTNER_SESSION_ID,
  COOKIES_USER_LOCATION,
  PARTNER_SESSION_EXPIRY,
  REFERER_COOKIE_EXPIRATION,
  REFERER_STORAGE_KEY,
  UTM_COOKIE_EXPIRATION,
  UTM_STORAGE_KEY,
} from 'lib/constants'

import useRouteMatch from './useRouteMatch'

// #region set cookie in server side and browser side
const setInCookie = ({ key, value, expiration }: { key: string; value: any; expiration?: number }) => {
  const stringValue = typeof value === 'object' ? JSON.stringify(value) : value
  if (isBrowser) {
    setBrowserCookie(key, stringValue, expiration)
  } else {
    return serialiseCookie(key, stringValue, expiration)
  }
}
//#endregion

// #region get cookie from browser or server request
const getFromCookie = ({
  key,
  serverCookies,
  defaultValue,
}: {
  key: string
  serverCookies: { [key: string]: string }
  defaultValue: string
}) => {
  if (isBrowser) {
    const value = getBrowserCookie(key)
    return { [key]: value || defaultValue }
  } else {
    return serverCookies
  }
}
//#endregion

// #region partner session handling
// Set cookies only when query params has partner info
const handlePartnerSession = ({
  serverCookies,
  queryParams,
}: {
  serverCookies: { [key: string]: string }
  queryParams: ParsedUrlQuery
}) => {
  const partnerCookie = getFromCookie({
    key: COOKIES_PARTNER_SESSION_ID,
    defaultValue: '{}',
    serverCookies,
  })
  const [partnerSessionInfo, isPartnerFromQueryParams, hasPartnerSession] = generatePartnerSession({
    queryParams,
    cookies: partnerCookie,
  })

  if (hasPartnerSession && isPartnerFromQueryParams) {
    const ssrPartnerCookie = setInCookie({
      key: COOKIES_PARTNER_SESSION_ID,
      value: {
        partnerId: partnerSessionInfo?.partnerId,
        sessionId: partnerSessionInfo?.sessionId,
        expires:
          Date.now() +
          (PARTNER_SESSION_EXPIRY[process.env.NEXT_PUBLIC_APP_ENV] || PARTNER_SESSION_EXPIRY['dev']) * 1000,
      },
    })
    // if server side, will have cookie as return value otherwise browser cookie is set so return variables will have no value
    return [hasPartnerSession, ssrPartnerCookie]
  }
  return [hasPartnerSession]
}
// #endregion partner session handling

// #region decide user currency handling
const handleUserCurrency = async ({
  hasPartnerSession,
  currencies,
  serverCookies,
  ssrKeyValues,
}: {
  hasPartnerSession: boolean
  currencies: Currency[]
  ssrKeyValues: Record<string, any>
  serverCookies: { [key: string]: string }
}) => {
  const currencyCookie = getFromCookie({ key: COOKIES_CURRENCY, serverCookies, defaultValue: '' })
  if (currencyCookie[COOKIES_CURRENCY]) return

  const userLocationCookie = JSON.parse(getBrowserCookie(COOKIES_USER_LOCATION) || '{}')
  const currency = await generateUserCurrency({
    cookies: currencyCookie,
    countryCode: ssrKeyValues?.countryCode || userLocationCookie?.countryCode || '',
    currencies,
    hasPartnerSession,
  })
  if (currency) {
    const ssrCurrencyCoockie = setInCookie({ key: COOKIES_CURRENCY, value: currency })
    // if server side, will have return value otherwise browser cookie is set so return variables will have no values
    return ssrCurrencyCoockie
  }
}
// #endregion decide user currency handling

// #region ds user id handling. If not found in cookie, generate new one
const handleDsUserId = ({ serverCookies }: { serverCookies: { [key: string]: string } }) => {
  const dsUserIdCookie = getFromCookie({ key: COOKIES_DS_USER_ID, serverCookies, defaultValue: '' })
  const dsUserId = generateDsUserId({ cookies: dsUserIdCookie })
  if (dsUserId) {
    const ssrDsUserIdCookie = setInCookie({ key: COOKIES_DS_USER_ID, value: dsUserId })
    // if server side, will have return value otherwise browser cookie is set so return variables will have no values
    if (ssrDsUserIdCookie) return ssrDsUserIdCookie
  }
}
//#endregion

// #region attribution handling
// identity from what channel user is coming
const handleUserAttribution = ({
  queryParams,
  serverCookies,
  referer,
}: {
  queryParams: ParsedUrlQuery
  serverCookies: { [key: string]: string }
  referer?: string
}) => {
  const firstClickUtms = getFromCookie({ key: COOKIES_FIRST_CLICK, serverCookies, defaultValue: '' })
  const [lastUtm, lastClickUtm, firstClickUtm] = generateUtms({
    queryParams,
    cookies: firstClickUtms,
    referer,
  })
  let ssrFirstClickCookie, ssrLastClickCookie, ssrLastUtmCookie
  if (firstClickUtm) {
    ssrFirstClickCookie = setInCookie({
      key: COOKIES_FIRST_CLICK,
      value: firstClickUtm,
      expiration: COOKIES_FIRST_CLICK_EXPIRATION,
    })
  }
  if (lastClickUtm) {
    ssrLastClickCookie = setInCookie({
      key: COOKIES_LAST_CLICK,
      value: lastClickUtm,
      expiration: COOKIES_LAST_CLICK_EXPIRATION,
    })
  }
  if (lastUtm) {
    ssrLastUtmCookie = setInCookie({
      key: UTM_STORAGE_KEY,
      value: lastUtm,
      expiration: UTM_COOKIE_EXPIRATION,
    })
  }
  // if server side, will have return value otherwise browser cookie is set so return variables will have no values
  return [ssrFirstClickCookie, ssrLastClickCookie, ssrLastUtmCookie].filter(Boolean)
}
// #endregion

const requestHandler = async ({
  queryParams,
  serverCookies = {},
  currencies,
  ssrKeyValues = {},
}: {
  queryParams: ParsedUrlQuery
  serverCookies?: { [key: string]: string }
  currencies: Currency[]
  ssrKeyValues?: Record<string, any>
}) => {
  const serverSetCookie = []

  const ssrDsUserId = handleDsUserId({ serverCookies })
  if (ssrDsUserId) serverSetCookie.push(ssrDsUserId)

  const [hasPartnerSession, ssrPartnerCookie] = handlePartnerSession({ serverCookies, queryParams })
  if (ssrPartnerCookie) serverSetCookie.push(ssrPartnerCookie)

  const ssrCurrencyCoockie = await handleUserCurrency({
    hasPartnerSession,
    currencies,
    serverCookies,
    ssrKeyValues,
  })
  if (ssrCurrencyCoockie) serverSetCookie.push(ssrCurrencyCoockie)

  const referer = ssrKeyValues?.referer || ''
  if (ssrKeyValues?.referer) {
    setInCookie({ key: REFERER_STORAGE_KEY, value: referer, expiration: REFERER_COOKIE_EXPIRATION })
  }

  const ssrUtms = handleUserAttribution({ queryParams, serverCookies, referer })
  if (ssrUtms && ssrUtms.length > 0) serverSetCookie.push(...ssrUtms)

  return serverSetCookie
}
const useAppGetInitialProps = ({ globalArgs }: { globalArgs: GlobalArgs }) => {
  const isProductDetailPage = useRouteMatch(PRODUCT_ROUTE)

  const { currencies = [] } = globalArgs || {}

  useEffect(() => {
    if (!isProductDetailPage || !isBrowser) return

    // useRouter hook not working properly for cached page to get query params hence native javascript way is used
    // assumption is cached page is render immediately while nextjs resources are yet to load or not ready
    const urlSearchParams = new URLSearchParams(window.location.search)
    const queryParams = Object.fromEntries(urlSearchParams.entries())

    requestHandler({ queryParams, currencies })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}

export { useAppGetInitialProps, requestHandler }
