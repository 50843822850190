import { MouseEventHandler, ReactNode, forwardRef, useRef } from 'react'
import cn from 'classnames'

import { useToggle } from 'lib/hooks/useToggle'
import useClickOutside from 'lib/hooks/useClickOutside'

import { LayoutButton as Button } from '../button'
import { LayoutButtonProps } from '../button/types'

import s from './styles.module.scss'

export interface DropdownButtonProps extends LayoutButtonProps {
  dropdown: ((data: { onClose: () => void }) => ReactNode) | ReactNode
  dropdownButton?: ReactNode
  direction?: 'top' | 'bottom'
  className?: string
  dropdownContainerClassName?: string
  dropDownClassName?: string
  behaviour?: 'hover' | 'click'
}

const DropdownButton = forwardRef<HTMLButtonElement, DropdownButtonProps>(
  (
    {
      dropdown,
      dropdownButton,
      direction = 'bottom',
      className,
      dropdownContainerClassName,
      dropDownClassName,
      behaviour = 'hover',
      variant,
      onClick,
      ...buttonProps
    },
    ref
  ) => {
    const dropdownRef = useRef<HTMLDivElement>(null)
    const [isDropdownMenuOpen, { onToggle, onClose }] = useToggle(false)

    useClickOutside(dropdownRef, () => {
      onClose()
    })

    const toggleDropdownMenu: MouseEventHandler<HTMLButtonElement> = (event) => {
      onToggle()
      onClick?.(event)
    }

    const isTransparentVariant = variant?.includes('transparent')

    return (
      <div ref={dropdownRef} className={cn(s.dropdown, s[`behaviour-${behaviour}`], className)}>
        {dropdownButton || (
          <Button
            {...buttonProps}
            variant={variant}
            onClick={behaviour === 'click' ? toggleDropdownMenu : onClick}
            className={cn(s.dropdownTrigger, { [s.avatar]: variant === 'avatar' })}
            ref={ref}
          />
        )}
        <div
          className={cn(
            s.dropdownMenu,
            [s[`direction-${direction}`]],
            {
              [s.isOpen]: behaviour === 'click' && isDropdownMenuOpen,
            },
            dropdownContainerClassName
          )}
        >
          <div
            className={cn(
              s.dropdownContent,
              {
                [s.roundedBorder]: isTransparentVariant,
              },
              dropDownClassName
            )}
          >
            {typeof dropdown === 'function' ? dropdown({ onClose }) : dropdown}
          </div>
        </div>
      </div>
    )
  }
)

DropdownButton.displayName = 'DropdownButton'

export { DropdownButton }
