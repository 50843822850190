import { createContext, useEffect, useContext, useMemo, ReactNode, useCallback, useState } from 'react'
import { useLazyQuery } from '@apollo/client'

import { logError } from 'lib/utils'

import { DESTINATION_LIST_BASED_ON_REGION_QUERY } from 'gql'

type DestinationByRegionContextType = {
  data: DestinationByRegion | undefined
  regions: Region[] | undefined
  page: number
  loading: boolean
  initialLoading: boolean
  fetchMore: () => void
}

type DestinationByRegionProviderProps = {
  children: ReactNode
}

const DESTINATIONS_BY_REGION_PAGE_SIZE = 10

const DestinationByRegionContext = createContext<DestinationByRegionContextType | null>(null)

export const useDestinationByRegionContext = () => {
  const context = useContext(DestinationByRegionContext)
  if (!context) {
    throw new Error('useDestinationByRegionContext must be used within DestinationByRegionProvider')
  }

  return context
}

export const DestinationByRegionProvider = ({ children }: DestinationByRegionProviderProps) => {
  const [destinationsByRegion, setDestinationsByRegion] = useState<DestinationByRegion>()
  const [page, setPage] = useState(1)
  const [loading, setLoading] = useState(false)
  const [initialLoading, setInitialLoading] = useState(false)
  const [regions, setRegions] = useState<Region[]>()

  const [fetchDestinationBasedOnRegion, { fetchMore }] = useLazyQuery(
    DESTINATION_LIST_BASED_ON_REGION_QUERY.query
  )

  const fetchMoreDestinationsByRegion = useCallback(async () => {
    setPage((currentPage) => currentPage + 1)
    setLoading(true)
    try {
      const response = await fetchMore({
        variables: { pageSize: DESTINATIONS_BY_REGION_PAGE_SIZE, page: page + 1 },
      })
      const destinationByRegionData = response.data[
        DESTINATION_LIST_BASED_ON_REGION_QUERY.queryName
      ] as DestinationByRegion

      setDestinationsByRegion((curDestinationsByRegion) => {
        if (!curDestinationsByRegion) return

        return {
          ...curDestinationsByRegion,
          groupedByRegion: curDestinationsByRegion.groupedByRegion.map((regionGroup) => {
            const matchRegionGroup = destinationByRegionData.groupedByRegion.find(
              (topRegionGroup) => topRegionGroup.region.regionId === regionGroup.region.regionId
            )
            if (!matchRegionGroup) {
              return regionGroup
            }

            return {
              ...regionGroup,
              destinationList: {
                destinationCount: matchRegionGroup.destinationList.destinationCount,
                destinations: [
                  ...regionGroup.destinationList.destinations,
                  ...matchRegionGroup.destinationList.destinations,
                ],
              },
            }
          }),
        } as DestinationByRegion
      })
      setLoading(false)
    } catch (error) {}
  }, [fetchMore, page])

  useEffect(() => {
    const onFetchDestinationByRegion = async () => {
      setInitialLoading(true)
      try {
        const response = await fetchDestinationBasedOnRegion({
          variables: { pageSize: DESTINATIONS_BY_REGION_PAGE_SIZE, page: page },
        })
        const { data } = response
        const destinationByRegionData = data?.[
          DESTINATION_LIST_BASED_ON_REGION_QUERY.queryName
        ] as DestinationByRegion

        if (!destinationByRegionData) {
          return
        }

        const regions = destinationByRegionData.groupedByRegion
          .filter((regionGroup) => regionGroup.destinationList.destinationCount > 0)
          .map((regionGroup) => regionGroup.region)

        setDestinationsByRegion(destinationByRegionData)
        setRegions(regions) // only set regions when fetching destinationByRegions for the first time
        setInitialLoading(false)
      } catch (error) {
        logError(error)
      }
    }

    onFetchDestinationByRegion()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const value = useMemo(() => {
    return {
      data: destinationsByRegion,
      regions,
      page,
      loading: loading,
      initialLoading: initialLoading,
      fetchMore: fetchMoreDestinationsByRegion,
    }
  }, [destinationsByRegion, regions, page, loading, initialLoading, fetchMoreDestinationsByRegion])

  return <DestinationByRegionContext.Provider value={value}>{children}</DestinationByRegionContext.Provider>
}
