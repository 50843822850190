import { useEffect, useMemo, useState } from 'react'
import { useRouter } from 'next/router'
import { useLazyQuery } from '@apollo/client'

import { isBrowser } from 'lib/utils'

import { PRODUCT_ROUTE } from 'lib/constants/routes'

import { CAMPAIGN_QUERY } from 'gql'

import useRouteMatch from './useRouteMatch'

const usePopupOverlaySequence = ({ isPromoEnabled = false }: { isPromoEnabled?: boolean }) => {
  // This hook is used to sequence the below popup/overlays on the page.
  // TTD Popup on PDP page
  // Campaign banner overlay on any page
  // Google one tap overlay on any page

  const router = useRouter()
  const { query } = router
  const isPdpPage = useRouteMatch(PRODUCT_ROUTE)
  const [getCampaign, { data, called: campaignCalled, loading: campaignLoading }] = useLazyQuery(
    CAMPAIGN_QUERY.query
  )
  // taken seperate state of page render to make sure required resources reflect correctly.
  const [isPageRendered, setPageRendered] = useState(false)

  const isEligibleForTTDPromo = () => {
    if (!isPdpPage || !isPromoEnabled) return false

    const { utm_medium, utm_campaign } = query
    if (utm_medium === 'ttd' && !utm_campaign) return true

    return false
  }

  const isEligibleForCampaign = () => {
    const { utm_campaign: utmCampaign = '', utm_source: utmSource = '' } = router.query
    const hasCampaign = utmCampaign?.length > 0 || utmSource?.length > 0
    if (!hasCampaign) return false

    return { campaignId: utmCampaign, campaignSource: utmSource }
  }

  const { isTTDUser, isCampaign } = useMemo(() => {
    if (!isBrowser || !isPageRendered) return { isTTDUser: false, isCampaign: false }

    const isTTDUser = isEligibleForTTDPromo()

    const isCampaign = !isTTDUser && isEligibleForCampaign()
    return { isTTDUser, isCampaign }
  }, [isPageRendered])

  useEffect(() => {
    setPageRendered(true)
  }, [])

  useEffect(() => {
    if (!!isCampaign && !campaignCalled) {
      const { campaignId, campaignSource } = isCampaign as Record<string, string>
      getCampaign({ variables: { campaignId, campaignSource } })
    }
  }, [isCampaign, campaignCalled, getCampaign])

  if (isTTDUser) return { isTTDUser }
  if (isCampaign && (campaignLoading || !campaignCalled)) return { isTTDUser }
  if (isCampaign && data?.[CAMPAIGN_QUERY.queryName]?.campaignId)
    return { campaignData: data?.[CAMPAIGN_QUERY.queryName] }

  // disable GTM for automation as crashing 3rd party scripts in GTM causing tests to fail
  // crashing blocks the next possible UI elements to be render or interact
  return { isGtm: query.utm_source !== 'test_automation' }
}

export { usePopupOverlaySequence }
