import { useEffect, useCallback, useMemo, useRef } from 'react'
import debounce from 'lodash-es/debounce'
import throttle from 'lodash-es/throttle'
import { useRouter } from 'next/router'

import { useAppData } from 'lib/context/app-data-context'

import { EVENTS } from 'lib/constants/events'
import {
  ARTICLE_COUNTRY_ROUTE,
  ARTICLE_DESTINATION_ROUTE,
  ARTICLE_DETAIL_ROUTE,
  ARTICLE_HOME_ROUTE,
} from 'lib/constants/routes'

type useExitIntentProps = {
  productId?: string | number
  onFocus?: boolean
  onBlur?: boolean
  onScroll?: boolean
  onMouseLeave?: boolean
  exitPromoModal?: Record<string, any>
  exitPromoCallback?: any
}

/**
 * Hook to track user exit intent
 * It tracks the following events:
 * - onFocus: When the user focuses on the page
 * - onBlur: When the user leaves the page
 * - onScroll: When the user scrolls the page
 * - onMouseLeave: When the user leaves the page from the top of the browser window
 * It also tracks the last touch position and mouse position
 * */

const EXIT_PROMO_EVENTS = ['onBlur', 'onMouseLeave', 'onScroll', 'onMouseMoveEnd']

const useExitIntent = ({
  productId,
  onFocus = false,
  onBlur = false,
  onScroll = false,
  onMouseLeave = false,
  exitPromoCallback,
}: useExitIntentProps) => {
  const { pathname } = useRouter()
  const { trackEvent } = useAppData()
  const scrollTimeout = 1000

  const ROUTES_TO_IGNORE = [
    ARTICLE_HOME_ROUTE,
    ARTICLE_COUNTRY_ROUTE,
    ARTICLE_DESTINATION_ROUTE,
    ARTICLE_DETAIL_ROUTE,
  ]
  const isCurrentRouteToIgnore = ROUTES_TO_IGNORE.includes(`${pathname}/`)

  // Initialize variables to hold timestamps and scroll positions
  const scroller: Record<string, any> = useMemo(
    () => ({
      startTime: 0,
      startPosition: 0,
      lastScrollTop: 0,
      endTimeout: null,
    }),
    []
  )

  const scrollEndRef = useRef<any>(null)
  const lastTouchPosition = useRef<Array<number> | null>(null)
  const lastMousePosition = useRef<Array<number> | null>(null)

  // Create an object to hold the event tracking functions
  const eventTrackData = useMemo(
    () => ({
      onFocus: () => {
        trackEvent.current(
          {
            attributeType: EVENTS.ATTRIBUTES_TYPE.PAGE,
            eventType: EVENTS.TYPE.FOCUS,
          },
          {
            pageNameAsAttributeId: true,
          }
        )

        exitPromoCallback?.()
        scrollEndRef.current && clearTimeout(scrollEndRef.current)
      },
      onBlur: (_event: any, _lastTouchPosition: Array<number> | null) => {
        trackEvent.current({
          attributeId: EVENTS.PRODUCT_DETAIL,
          attributeType: EVENTS.ATTRIBUTES_TYPE.PAGE,
          eventType: EVENTS.TYPE.BLUR,
          attributeValue: {
            pageId: productId as string,
            timestamp: Date.now(),
            // Set the last touch position if it exists, otherwise use the last mouse position
            scrollPosition: _lastTouchPosition || lastMousePosition.current,
            screenSize: [window.innerWidth, window.innerHeight],
          },
        })

        exitPromoCallback?.()
      },
      onMouseLeave: (_event: any) => {
        trackEvent.current({
          attributeId: EVENTS.PRODUCT_DETAIL,
          attributeType: EVENTS.ATTRIBUTES_TYPE.PAGE,
          eventType: EVENTS.TYPE.BLUR,
          attributeValue: {
            pageId: productId as string,
            timestamp: Date.now(),
            // Last mouse position when the user leaves the page from the top of the browser window
            scrollPosition: [_event.clientX, _event.clientY],
            screenSize: [window.innerWidth, window.innerHeight],
          },
        })

        exitPromoCallback?.()
      },
      onScroll: (scrollValues: any) => {
        trackEvent.current({
          attributeId: EVENTS.PRODUCT_DETAIL,
          attributeType: EVENTS.ATTRIBUTES_TYPE.PAGE,
          eventType: EVENTS.TYPE.FOCUS,
          attributeValue: {
            pageId: productId as string,
            timestamp: Date.now(),
            lastPosition: scrollValues.lastPosition,
            currentPosition: scrollValues.currentPosition,
            duration: scrollValues.duration,
            documentSize: [document.documentElement.clientWidth, document.documentElement.clientHeight],
          },
        })

        exitPromoCallback?.()
      },
      onMouseIdle: () => {
        exitPromoCallback?.()
      },
    }),
    [exitPromoCallback, productId, trackEvent]
  )

  const onFocusHandler = useCallback(() => {
    if (window?.document?.hasFocus()) {
      eventTrackData.onFocus?.()
    }
  }, [eventTrackData])

  const onBlurHandler = useCallback(
    (event: any) => {
      eventTrackData.onBlur?.(event, lastTouchPosition.current)
    },
    [eventTrackData, lastTouchPosition]
  )

  // Function to handle mouse move event
  const onMouseMove = debounce((event: any) => {
    lastMousePosition.current = [event.pageX, event.pageY]
  }, 100)

  // Reset the last touch position when the user starts touching the screen
  const onTouchStartHandler = useCallback(() => {
    lastTouchPosition.current = null
  }, [])

  // Record the last touch position when the user stops touching the screen
  const onTouchEndHandler = useCallback(
    (e: any) => {
      lastTouchPosition.current = [e.changedTouches[0].clientX, e.changedTouches[0].clientY]
    },
    [lastTouchPosition]
  )

  // Set a timeout to record the end scroll position after the user stops scrolling
  const handleScrollEvent = useCallback(() => {
    // Record start timestamp if it's the beginning of scrolling
    if (scroller.startTime === 0) {
      scroller.startTime = new Date().getTime()
      scroller.startPosition = window.scrollY
      scrollEndRef.current && clearTimeout(scrollEndRef.current)
    }

    // Reset previous timeout
    clearTimeout(scroller.endTimeout)

    // Set a timeout to record end timestamp after scrolling ends
    scroller.endTimeout = setTimeout(function () {
      const topPageArea = document.documentElement.scrollHeight * 0.25
      const bottomPageArea = document.documentElement.scrollHeight * 0.75

      const scrollTimeDifference = new Date().getTime() - scroller.startTime

      if (
        scrollTimeDifference <= 3000 &&
        scroller.startPosition >= bottomPageArea &&
        window.scrollY <= topPageArea
      ) {
        eventTrackData.onScroll?.({
          lastPosition: scroller.startPosition,
          currentPosition: window.scrollY,
          duration: new Date().getTime() - scroller.startTime,
        })

        // Reset start timestamp for the next scroll event
        scroller.startTime = 0
        scroller.startPosition = null
      } else {
        scroller.startTime = 0
        scroller.startPosition = null
      }
    }, scrollTimeout)
  }, [eventTrackData, scroller, scrollTimeout])

  // Attach scroll event listener to the window
  const scrollHandler = throttle(
    useCallback(() => {
      const st = window.pageYOffset || document.documentElement.scrollTop
      // If the user is scrolling up, record the scroll event
      if (st < scroller.lastScrollTop) {
        handleScrollEvent()
      }

      scroller.lastScrollTop = st <= 0 ? 0 : st // For Mobile or negative scrolling
    }, [handleScrollEvent, scroller]),
    100
  )

  // Handler to check if the user has left the page from the top of the browser window
  const mouseLeaveHandler = debounce((e: any) => {
    if (e.clientY <= 0) {
      eventTrackData.onMouseLeave?.(e)
    }
  }, 100)

  // Function to handle mouse move end  event
  const onMouseIdle = debounce(() => {
    scrollEndRef.current && clearTimeout(scrollEndRef.current)

    scrollEndRef.current = setTimeout(() => {
      eventTrackData?.onMouseIdle?.()
    }, 60000)
  }, 500)

  useEffect(() => {
    if (!isCurrentRouteToIgnore) {
      setTimeout(() => {
        onFocus && window.addEventListener('focus', onFocusHandler)
        onScroll && window.addEventListener('scroll', scrollHandler)
        onMouseLeave && document.body.addEventListener('mouseleave', mouseLeaveHandler)
        EXIT_PROMO_EVENTS.includes('onMouseMoveEnd') && window.addEventListener('mousemove', onMouseIdle)

        if (onBlur) {
          window.addEventListener('blur', onBlurHandler)
          window.addEventListener('mousemove', onMouseMove)
          // check if touch events are supported
          if ('ontouchstart' in window) {
            window.addEventListener('touchstart', onTouchStartHandler)
            window.addEventListener('touchend', onTouchEndHandler)
          }
        }
      }, 1)
    }

    // remove the listeners
    return () => {
      onFocus && window.removeEventListener('focus', onFocusHandler)
      onScroll && window.removeEventListener('scroll', scrollHandler)
      onMouseLeave && document.body.removeEventListener('mouseleave', mouseLeaveHandler)
      EXIT_PROMO_EVENTS.includes('onMouseMoveEnd') && window.removeEventListener('mousemove', onMouseIdle)

      if (onBlur) {
        window.removeEventListener('blur', onBlurHandler)
        window.addEventListener('mousemove', onMouseMove)
        if ('ontouchstart' in window) {
          window.removeEventListener('touchstart', onTouchStartHandler)
          window.removeEventListener('touchend', onTouchEndHandler)
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCurrentRouteToIgnore])
}

export default useExitIntent
