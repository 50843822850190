import cn from 'classnames'
import { forwardRef } from 'react'

import { Icon } from 'ui/icon'

import s from './styles.module.scss'

type QueryCardProps = {
  className?: string
  onClick?: () => void
  query: string
}

const QueryCard = forwardRef<HTMLDivElement, QueryCardProps>(({ query, className, onClick }, ref) => {
  return (
    <div ref={ref} className={cn(s.card, className)} onClick={onClick} tabIndex={-1}>
      <div className={s.destination}>
        <Icon name="magnifying-glass" size="medium" />
      </div>

      <div className={s.body}>
        <div className={s.content}>
          <h4 className={s.title}>{query}</h4>
        </div>
        <Icon name="arrow-right" size="medium" className={s.hoverIcon} />
      </div>
    </div>
  )
})

QueryCard.displayName = 'QueryCard'

export { QueryCard }
